<template>
    <SearchInput
        :absolute-results="absoluteResults"
        :loading="loading"
        :results="results"
        :force-hide-results="hideResults"
        @on-search="onSearch"
        @item-selection="onSelectItem"
    />
</template>
<script>
import debounce from 'lodash.debounce';
import SearchInput from './SearchInput.vue';

export default {
    name: 'SearchInputAjax',
    components: { SearchInput },

    props: {
        absoluteResults: {
            type: Boolean,
            default: true,
        },
        endpoint: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
        },
        initialResults: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            results: this.initialResults,
            hideResults: (this.initialResults.length === 0),
            loading: false,
        };
    },

    methods: {
        /**
         * @param {string} search
         */
        onSearch: debounce(function (search) {
            this.hideResults = true;
            if (search.length < 3) {
                return;
            }
            this.loading = true;
            this.$http
                .get(this.endpoint, { params: { search, ...this.params } })
                .then((response) => {
                    this.results = response.data.data;
                    this.hideResults = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        }, 500),
        onSelectItem(item) {
            this.$emit('item-selection', item);
        },
    },
};
</script>
