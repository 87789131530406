<template>
    <div>
        <div>
            <div class="d-flex justify-content-between mt-4 mb-4">
                <div class="label">
                    {{ label }}
                </div>
                <span
                    v-if="hasTooltip"
                    v-tooltip.right="tooltip"
                    class="ml-3 cursor-help"
                >
                    <FontAwesomeIcon icon="info-circle" />
                </span>
            </div>
        </div>

        <div class="row input-dates-wrapper">
            <HtFormInput
                v-if="offsetInputShown"
                id="offsetNumber"
                :value="Math.abs(offsetNumber)"
                name="offsetNumber"
                type="number"
                class="col-2"
                :disabled="disabled"
                cypress="offsetNumber"
                @input="$emit('update:offset-number', isAfter ? (+Math.abs(parseInt($event))) : (-Math.abs(parseInt($event))))"
            />
            <HtFormSelect
                id="offsetUnit"
                v-model="offsetUnitValue"
                v-validate.disable="'required'"
                class="col"
                name="offsetUnit"
                :options="offsetUnitOptions"
                :open-direction="openSelectDirection"
                :data-vv-as="translate('date')"
                :disabled="disabled"
                cypress="offsetUnit"
            />

            <HtFormSelect
                v-if="isAfterInputShown"
                id="isAfter"
                v-model="isAfter"
                v-validate.disable="'required'"
                class="col-2 is-after-input"
                name="isAfter"
                :options="isAfterOrBeforeOptions"
                :open-direction="openSelectDirection"
                :data-vv-as="translate('date')"
                :disabled="disabled"
                cypress="isAfter"
            />

            <template v-if="keyDatesSelectShown">
                <HtFormFlatPickr
                    v-if="isSpecificDate"
                    id="specific-date"
                    v-validate.disable="'required'"
                    :value="offsetSpecificDate"
                    class="col"
                    name="specific-date"
                    :min-date="specificMinDate"
                    :data-vv-as="translate('date')"
                    :is-working-day="useWorkingDays"
                    :disabled="disabled"
                    :static="false"
                    cypress="specific-date"
                    @input="$emit('update:offset-specific-date', $event)"
                />
                <template v-else>
                    <HtFormSelect
                        v-if="canSelectKeyDate"
                        id="offsetKeyDate"
                        v-model="offsetKeyDateValue"
                        v-validate.disable="'required'"
                        class="col"
                        name="offsetKeyDate"
                        :options="keyDatesOptions"
                        :open-direction="openSelectDirection"
                        :data-vv-as="translate('date')"
                        :disabled="disabled"
                        cypress="offsetKeyDate"
                    />

                    <HtFormInput
                        v-else
                        id="offsetKeyDate"
                        name="offsetKeyDate"
                        :value="translate(offsetUnitValue === 'day_of' ? 'Availability date' : 'After Availability date')"
                        class="col"
                        disabled
                    />
                </template>
            </template>
        </div>

        <template v-if="hasResourceDependency">
            <HtUserTaskDependency
                v-if="isUserProgramContext"
                :dependents="dependents"
                :dependencies="dependencies"
                :company-user-program-id="userProgramId"
                :resource-id="resourceId"
                :resource-type="resourceType"
                :disabled="disabled"
            />

            <HtResourceDependency
                v-else
                :dependents="dependents"
                :dependencies="dependencies"
                :filters="filters"
                :company-program-id="programId"
                :resource-id="resourceId"
                :resource-type="resourceType"
            />
        </template>
    </div>
</template>

<script>
import CompanyProgramResourceFilterCollection from '@/models/CompanyProgramResourceFilterCollection';
import HtResourceDependency from '@/components/globals/HtResourceDependency.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtUserTaskDependency from '@/components/globals/HtUserTaskDependency.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import HtFormFlatPickr from '@/components/globals/HtFormFlatPickr.vue';

export default {
    name: 'HtKeyDatesInputOrderSelect',

    components: {
        HtFormFlatPickr,
        HtFormSelect,
        HtUserTaskDependency,
        HtResourceDependency,
        HtFormInput,
    },

    props: {
        label: {
            type: String,
            default: 'Label',
        },
        tooltip: {
            type: String,
            default: 'Tooltip desc',
        },
        hasTooltip: {
            type: Boolean,
            default: true,
        },
        openSelectDirection: {
            type: String,
            default: 'bottom',
        },
        keyDates: {
            type: Array,
            required: true,
        },
        programId: {
            type: Number,
            default: null,
        },
        userProgramId: {
            type: Number,
            default: null,
        },
        offsetKeyDate: {
            type: [Number, String],
            default: null,
        },
        offsetNumber: {
            type: Number,
            required: true,
        },
        offsetUnit: {
            type: String,
            required: true,
        },
        offsetSpecificDate: {
            type: String,
            default: null,
        },
        dependents: {
            type: [Object, Array],
            default: () => ([]),
        },
        dependencies: {
            type: [Object, Array],
            default: () => ([]),
        },
        filters: {
            type: [CompanyProgramResourceFilterCollection, Object, Array],
            default: () => ({}),
        },
        resourceId: {
            type: Number,
            default: 0,
        },
        resourceType: {
            type: String,
            default: null,
        },
        hasImmediatelyUnitOption: {
            type: Boolean,
            default: true,
        },
        canSelectKeyDate: {
            type: Boolean,
            default: true,
        },
        canSelectSpecificDate: {
            type: Boolean,
            default: false,
        },
        specificMinDate: {
            type: String,
            default: null,
        },
        useWorkingDays: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isAfter: this.offsetNumber >= 0,
            offsetKeyDateValue: this.dependents.length > 0 ? 'resourceDependency' : this.offsetKeyDate,
            offsetUnitValue: this.offsetUnit,
            pickrDate: null,

            isAfterOrBeforeOptions: [
                {
                    id: true,
                    name: this.translate('After'),
                },
                {
                    id: false,
                    name: this.translate('Before'),
                },
            ],
        };
    },

    computed: {
        keyDatesOptions() {
            const keyDates = [...this.keyDates];

            if (this.resourceType !== null) { // If resourceType is given, so we can choose resourceDependency as "keyDate"
                keyDates.push({
                    id: 'resourceDependency',
                    name: this.translate('After completing a task'),
                });
            }

            return keyDates;
        },

        offsetUnitOptions() {
            let options = [];

            if (this.hasImmediatelyUnitOption) {
                options.push({
                    id: 'immediately',
                    name: this.translate('Immediately'),
                });
            }

            options = options.concat([
                {
                    id: 'day_of',
                    name: this.translate('Day of'),
                },
                {
                    id: 'days',
                    name: Math.abs(this.offsetNumber) < 2 ? this.translate('Day') : this.translate('Days'),
                    $isDisabled: this.hasResourceDependency,
                },
                {
                    id: 'weeks',
                    name: Math.abs(this.offsetNumber) < 2 ? this.translate('Week') : this.translate('Weeks'),
                    $isDisabled: this.hasResourceDependency,
                },
                {
                    id: 'months',
                    name: Math.abs(this.offsetNumber) < 2 ? this.translate('Month') : this.translate('Months'),
                    $isDisabled: this.hasResourceDependency,
                },
            ]);

            if (this.canSelectSpecificDate) {
                options.push({
                    id: 'specific_date',
                    name: this.translate('Specific date'),
                });
            }

            return options;
        },

        keyDatesSelectShown() {
            return !['immediately'].includes(this.offsetUnitValue);
        },

        offsetInputShown() {
            return !this.isSpecificDate && !['immediately', 'day_of'].includes(this.offsetUnitValue);
        },

        isAfterInputShown() {
            return this.offsetInputShown && !this.hasResourceDependency && this.canSelectKeyDate;
        },

        hasResourceDependency() {
            return !!this.resourceType && this.offsetKeyDateValue === 'resourceDependency';
        },

        isSpecificDate() {
            return this.canSelectSpecificDate && this.offsetUnitValue === 'specific_date';
        },

        isUserProgramContext() {
            return !!this.userProgramId;
        },
    },

    watch: {
        offsetUnitValue() {
            if (['day_of', 'immediately'].includes(this.offsetUnitValue)) { // If "immediately" or "day_of" offset unit value is selected
                this.$emit('update:offset-number', 0); // Reset offset number to 0

                if (this.offsetUnitValue === 'immediately') { // If "immediately" offset unit value is selected
                    this.offsetKeyDateValue = null; // Reset key date selection
                }
            }

            this.$emit('update:offset-unit', this.offsetUnitValue === 'day_of' ? 'days' : this.offsetUnitValue);
        },

        isAfter() {
            if (this.offsetNumber !== 0) {
                if (this.isAfter && this.offsetNumber < 0) {
                    this.$emit('update:offset-number', +Math.abs(this.offsetNumber)); // Set positive value
                } else if (!this.isAfter && this.offsetNumber > 0) {
                    this.$emit('update:offset-number', -Math.abs(this.offsetNumber)); // Set negative value
                }
            }
        },

        offsetKeyDateValue() {
            if (this.hasResourceDependency) {
                this.isAfter = true;
                this.offsetUnitValue = 'day_of';
                this.$emit('update:offset-key-date', null);
            } else {
                this.$emit('update:offset-key-date', this.offsetKeyDateValue);
            }
        },

        canSelectKeyDate() {
            if (!this.canSelectKeyDate) {
                this.offsetKeyDateValue = null;
            }
        },

        offsetKeyDate() {
            if (!this.hasResourceDependency) {
                this.offsetKeyDateValue = this.offsetKeyDate;
            }
        },

        offsetNumber() {
            this.setOffsetUnitAccordingWithOffsetValues();
        },

        isAfterInputShown() {
            if (!this.isAfterInputShown) {
                this.isAfter = true;
            } else {
                this.isAfter = this.offsetNumber >= 0;
            }
        },
    },

    mounted() {
        if (this.offsetUnit === 'days' && this.offsetNumber === 0) {
            this.offsetUnitValue = 'day_of';
        }
    },

    methods: {
        setOffsetUnitAccordingWithOffsetValues() {
            if (this.offsetNumber !== 0) {
                this.offsetUnitValue = this.offsetUnit;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.label {
    font-size: 1.4rem;
    font-family: "latobold", "lato", sans-serif;
}

.is-after-input {
    max-width: none;
}

.input-dates-wrapper {
    .ht-form:not(:last-child) {
        padding-right: 0 !important;
    }
}
</style>
