import Vue from 'vue';
import api from '@/store/api';

/**
 * @typedef {Object} Event
 * @property {number} id
 * @property {number} email_sending_offset
 * @property {boolean} is_delegated
 * @property {string|null} link
 * @property {string|null} place
 * @property {string|null} time_end
 * @property {string|null} time_start
 * @property {string|null} timezone
 * @property {{content: Object<string, string>}} translations
 * @property {Role|undefined} organiser_role
 * @property {User|undefined} organiser_user
 * @property {FileTranslation[]} file_translations
 * @property {Resource} resource
 */

/**
 * @typedef {{data: {data: Event}}} EventResourceResponse
 * @typedef {{data: {data: Event[]}}} EventCollectionResponse
 */

export default {
    /**
     * @param {any} data
     * @returns {Promise<EventCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.post('configuration/events/list', data);
    },

    /**
     * @param {number|string} eventId
     * @param {number|string} fileId
     * @returns {Promise<{CompanyFileHT}>}
     */
    duplicateFile(eventId, fileId) {
        const params = {
            file: fileId,
            event: eventId,
        };

        const endpoint = api.utils.replaceVariables(
            api.endpoints.CONFIGURATION_EVENTS_DUPLICATION_FILE,
            params,
        );

        return Vue.prototype.$http.post(endpoint);
    },

    /**
     * @param {number|string} id
     * @param {import('axios').AxiosRequestConfig} config
     * @returns {Promise<{}>}
     */
    delete(id, config = {}) {
        return Vue.prototype.$http.delete(`configuration/events/${id}`, config);
    },

    /**
     * @param {number|string} id
     * @param {import('axios').AxiosRequestConfig} config
     * @returns {Promise<EventResourceResponse>}
     */
    get(id, config = {}) {
        return Vue.prototype.$http.get(`configuration/events/${id}`, config);
    },

    /**
     * @param {any} data
     * @returns {Promise<EventResourceResponse>}
     */
    create(data = {}) {
        return Vue.prototype.$http.post('configuration/events', data);
    },

    /**
     * @param {number|string} id
     * @param {any} data
     * @returns {Promise<EventResourceResponse>}
     */
    update(id, data = {}) {
        return Vue.prototype.$http.post(`configuration/events/${id}`, data);
    },

    /**
     * @param {number|string} id
     * @param {any} config
     * @returns {Promise<EventResourceResponse>}
     */
    replicate(id, config = {}) {
        return Vue.prototype.$http.post(`configuration/events/${id}/replicate`, config);
    },
};
