<template>
    <HtModal
        ref="modal"
        @beforeClose="beforeClose"
    >
        <template #header>
            <div>
                <t>Send data</t>
            </div>
        </template>
        <template #default>
            <div
                v-if="loading === false"
            >
                <HtFormMultiSelect
                    id="triggerRequirementTags"
                    v-model="chosenTags"
                    :cypress="`trigger-requirement-tags`"
                    :is-searchable="false"
                    :label="translate('Send data')"
                    :label-options="'name'"
                    :options="options"
                    :show-optional="false"
                    name="tags"
                    placeholder="Tag"
                />
                <div
                    v-if="items.length > 0"
                    class="tag-list"
                >
                    <div
                        v-for="(item, index) in items"
                        :key="index"
                        class="tag-list-item"
                    >
                        <div class="tag-list-item-name">
                            {{ item.category }}
                        </div>
                        <div class="tag-list-item-subname">
                            {{ item.name }}
                        </div>
                        <div
                            v-if="item.status === getDoneLabel"
                            class="tag-list-item-status text-green"
                        >
                            <font-awesome-icon :icon="['far', 'check-circle']" /> <t>ready to send</t>
                        </div>
                        <div
                            v-else-if="item.status === getNotConcernedLabel"
                            class="tag-list-item-status text-black"
                        >
                            <font-awesome-icon :icon="['far', 'check-circle']" /> <t>not concerned</t>
                        </div>
                        <div
                            v-else
                            class="tag-list-item-status text-orange"
                        >
                            <font-awesome-icon :icon="['far', 'exclamation-triangle']" /> <t>to complete</t>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="loading === true">
                <IconLoading />
            </div>
            <div v-else-if="items.length === 0">
                <t>No data to send</t>
            </div>
        </template>
        <template #footer>
            <div class="d-flex justify-content-end">
                <Button
                    class="negative mr-3"
                    @click="close()"
                >
                    <t>Exit</t>
                </Button>
                <Button
                    :state="buttonState"
                    @click="sendTags('post')"
                >
                    <t>Send</t>
                </Button>
            </div>
        </template>
    </HtModal>
</template>

<script>

import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import uniqWith from 'lodash.uniqwith';
import isEqual from 'lodash.isequal';
import orderBy from 'lodash.orderby';
import Notifier from '@/Notifier';
import I18n from '@/modules/i18n/I18n';

function data() {
    return {
        tags: [],
        chosenTags: [],
        loading: false,
        companyUserId: null,
    };
}

export default {
    name: 'UserRequirementByTagsModal',
    components: {
        HtFormMultiSelect,
    },
    data() {
        return data();
    },

    computed: {
        getDoneLabel() { return 'done'; },
        getNotConcernedLabel() { return 'not_concerned'; },
        buttonState() {
            if (this.chosenTags.length === 0) {
                return 'disabled';
            }

            if (this.loading === true) {
                return 'loading';
            }

            return 'idle';
        },
        options() {
            return this.tags.filter((tag) => tag.mustBeDisplayed).map((d) => ({
                name: d.name,
                id: d.slug,
                requirements: d.requirements,
            }));
        },
        items() {
            const reqs = [];
            this.chosenTags.forEach((tag) => {
                tag.requirements.forEach((req) => {
                    reqs.push(req);
                });
            });

            return uniqWith(orderBy(reqs, 'category', 'asc'), isEqual);
        },
    },

    methods: {
        open(companyUserId) {
            this.companyUserId = companyUserId;

            this.loadTags('get');

            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        beforeClose(resolve) {
            Object.assign(this.$data, data());

            resolve();
        },
        loadTags() {
            this.loading = true;

            this.$http.get(`triggers/requirements/tags/${this.companyUserId}`).then((response) => {
                this.tags = response.data.data;
                this.loading = false;
            });
        },
        sendTags() {
            const tags = [];

            this.chosenTags.forEach((tag) => {
                tags.push(tag.id);
            });

            this.$http.post(`triggers/requirements/tags/${this.companyUserId}`, { tags }).then(() => {
                Notifier.getInstance('App').showInfo(I18n.translate('Successfully sent'));
                this.close();
            }).catch(() => {
                Notifier.getInstance('App').showError(I18n.translate('Error while sending data'));
            });
        },
    },
};
</script>

<style lang="scss" scoped src="./UserRequirementByTagsModal.scss" />
