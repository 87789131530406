<template>
    <div>
        <form
            v-if="companyPlanningEvent"
            class="form-2 spaced user-planning-event-edit"
            data-cy="planning-event-form"
            @submit.prevent=""
        >
            <!-- General -->
            <ParametersBox :title="translate('General')">
                <HtFormInput
                    id="name"
                    v-model="companyPlanningEvent.name"
                    v-validate.disable="'required'"
                    :label="translate('Event name')"
                    name="name"
                    cypress="name"
                    type="text"
                    :data-vv-as="translate('name')"
                />

                <HtFormEditor
                    id="description"
                    v-model="companyPlanningEvent.description"
                    v-validate="descriptionValidation"
                    :label="`${translate('Description')}`"
                    name="description"
                    cypress="description"
                    :variables="textVariables"
                />
                <fieldset>
                    <label>
                        <t>Event link</t>
                        <span class="ht-form-optional"><t>Optional</t></span>
                    </label>
                    <HtFormInput
                        id="link"
                        v-model="companyPlanningEvent.link"
                        type="text"
                        name="link"
                        cypress="link"
                        :placeholder="translate('Event link ?')"
                    />
                </fieldset>
            </ParametersBox>

            <!-- Content -->
            <ParametersBox :title="translate('Content')">
                <fieldset>
                    <label>
                        <t>Invitation e-mail content</t>
                        <span class="ht-form-optional"><t>Optional</t></span>
                    </label>

                    <HtFormEditor
                        id="content"
                        v-model="companyPlanningEvent.content"
                        name="content"
                        cypress="content"
                        :variables="textVariables"
                    />

                    <HtFormSwitch
                        id="file-join"
                        v-model="is_file_box_checked"
                        :label="translate('Attach a file')"
                        name="file-join"
                        cypress="file-join"
                        :off-label="translate('No')"
                        :on-label="translate('Yes')"
                    />
                </fieldset>

                <fieldset
                    v-if="is_file_box_checked"
                    :class="{ error: errors.has('original_name') }"
                >
                    <transition name="fade">
                        <div>
                            <label>
                                <t>Upload file</t>
                                <span class="sub-text">(<t>Up to 20 MB</t>)</span></label>

                            <UploadBar
                                v-model="companyPlanningEvent.companyFile"
                                cypress="company-file"
                                :media="['office', 'pdf', 'image']"
                            />

                            <input
                                v-validate.disable="'required'"
                                :value="companyPlanningEvent.companyFile && companyPlanningEvent.companyFile.original_name"
                                :data-vv-as="translate('file')"
                                type="hidden"
                                name="original_name"
                            >
                        </div>
                    </transition>
                </fieldset>

                <fieldset>
                    <label>
                        <t>Invitation sending date</t>
                    </label>
                    <div class="event-offset-input">
                        <HtFormInput
                            id="email_sending_offset"
                            v-model.number="companyPlanningEvent.email_sending_offset"
                            v-validate.disable="'required'"
                            :min="0"
                            type="number"
                            name="email_sending_offset"
                            cypress="email-sending-offset"
                        />
                        <span>
                            <t :count="companyPlanningEvent.email_sending_offset">working day before the event | working days before the event</t>
                        </span>
                    </div>
                    <GuessOnWhichEmailItWillBeSend
                        v-if="professionalEmailSwitchDayOffset !== null"
                        :professional-email-switch-day-offset="professionalEmailSwitchDayOffset"
                        :item="companyPlanningEvent"
                        :company-user="companyUser"
                    />
                </fieldset>

                <fieldset :class="{ error: errors.has('organiser') }">
                    <label>
                        <t>Organiser</t>
                        *</label>
                    <ParticipantList
                        single-selection
                        type="stacked"
                        :users="organiser ? [organiser] : []"
                        :add-label="translate('Add an organizer')"
                        cypress="organiser-list"
                        @onAdd="openSearchRole"
                        @onRemove="resetOrganiser"
                    />

                    <HtFormInput
                        id="organiser"
                        v-model="organiser"
                        v-validate.disable="'required'"
                        type="hidden"
                        name="organiser"
                        :data-vv-as="translate('organizer')"
                    />
                </fieldset>

                <HtFormInput
                    id="place"
                    v-model="companyPlanningEvent.place"
                    type="text"
                    name="place"
                    cypress="place"
                    :label="translate('Event location')"
                    :placeholder="translate('Where will the event take place ?')"
                />
                <fieldset :class="{ error: errors.has('participants')}">
                    <label>
                        <t>Participants</t>
                        *</label>
                    <ParticipantList
                        can-add
                        type="stacked"
                        cypress="paticipant-list"
                        :users="companyPlanningEvent.attendees"
                        @onAdd="openAddUsersModal"
                    />

                    <HtFormInput
                        id="participants"
                        v-model="companyPlanningEvent.attendees"
                        v-validate.disable="'required'"
                        type="hidden"
                        name="participants"
                        :data-vv-as="translate('participants')"
                    />
                </fieldset>
            </ParametersBox>

            <!-- Delegation -->
            <div v-show="(!companyPlanningEvent.time_start || !companyPlanningEvent.time_end)">
                <ParametersBox :title="translate('Delegation')">
                    <HtFormSwitch
                        id="delegated"
                        v-model="companyPlanningEvent.is_delegated"
                        name="delegated"
                        :show-optional="false"
                        :label="translate('Delegate programming to the organizer')"
                        :description="translate('By activating this option, the organizer can modify the event schedule (date, time and duration)')"
                    />
                    <div
                        v-show="companyPlanningEvent.is_delegated"
                    >
                        <fieldset :class="{ error: errors.has('uuid') }">
                            <HtFormEditor
                                id="content-to-delegation"
                                v-model="companyPlanningEvent.content_to_delegation"
                                :label="translate('E-mail content')"
                                name="content_to_delegation"
                                :variables="textVariables"
                                cypress="event-email-content-to-delegation"
                                resource-openai="event"
                            />
                        </fieldset>
                        <fieldset>
                            <HtKeyDatesInputOrder
                                :resource-id="companyPlanningEvent.id"
                                :user-program-id="companyPlanningEvent.company_user_program_id"
                                :start-label="translate('Date delegation email sent')"
                                :is-business-day.sync="companyPlanningEvent.task.is_business_day"
                                :offset-key-date.sync="companyPlanningEvent.task.company_user_program_key_date_id"
                                :offset-availability-number.sync="companyPlanningEvent.task.offset_availability_number"
                                :offset-availability-unit.sync="companyPlanningEvent.task.offset_availability_unit"
                                :offset-end-number.sync="companyPlanningEvent.task.offset_end_number"
                                :offset-end-unit.sync="companyPlanningEvent.task.offset_end_unit"
                                :is-delegated="companyPlanningEvent.is_delegated"
                            />
                        </fieldset>
                    </div>
                </ParametersBox>
            </div>

            <!-- Programming -->
            <transition name="fade">
                <ParametersBox :title="translate('Programming')">
                    <div v-if="!companyPlanningEvent.is_delegated">
                        <HtUserProgramList
                            v-model="companyPlanningEvent.company_user_program_id"
                            :company-user-id="companyUserId"
                            disabled
                        />

                        <fieldset
                            class="event-date-time"
                            :class="{ error: errors.has('datetime_start') || errors.has('datetime_end') }"
                        >
                            <HtKeyDatesInputOrder
                                :user-program-id="companyUserProgramId"
                                :is-business-day.sync="companyPlanningEvent.task.is_business_day"
                                :offset-key-date.sync="companyPlanningEvent.task.company_user_program_key_date_id"
                                :offset-availability-specific-date.sync="companyPlanningEvent.task.datetime_availability"
                                :offset-availability-number.sync="companyPlanningEvent.task.offset_availability_number"
                                :offset-availability-unit.sync="companyPlanningEvent.task.offset_availability_unit"
                                :offset-end-specific-date.sync="companyPlanningEvent.task.datetime_end"
                                :offset-end-number.sync="companyPlanningEvent.task.offset_end_number"
                                :offset-end-unit.sync="companyPlanningEvent.task.offset_end_unit"
                                can-select-specific-date
                            />
                            <template
                                v-if="(companyPlanningEvent.task.status !== 'draft' && !isNew) || !companyPlanningEvent.is_delegated"
                            >
                                <PlanningTimeSlots
                                    :from.sync="companyPlanningEvent.time_start"
                                    :to.sync="companyPlanningEvent.time_end"
                                    :time_to="23"
                                />
                                <input
                                    v-model="companyPlanningEvent.time_start"
                                    v-validate="'required'"
                                    type="hidden"
                                    name="time_start"
                                >
                                <input
                                    v-model="companyPlanningEvent.time_end"
                                    v-validate="'required'"
                                    type="hidden"
                                    name="time_end"
                                >
                            </template>

                            <EventTimezoneManager :item="companyPlanningEvent" />
                        </fieldset>

                        <fieldset>
                            <label>
                                <t>Recurrence</t>
                            </label>
                            <RecurrenceUserConfig
                                :recurrence="recurrence"
                                @update="setRecurrence"
                            />
                        </fieldset>
                    </div>
                    <div v-else>
                        <div class="ht-form-label mb-3">
                            <t>The scheduling is delegated</t>
                        </div>
                        <p class="ht-form-description">
                            <t>Disable delegation to handle scheduling management.</t>
                        </p>
                    </div>
                </ParametersBox>
            </transition>

            <div class="button-container">
                <Button
                    v-show="canDelete"
                    :state="buttonState['delete']"
                    class="negative"
                    cypress="planning-event-delete-button"
                    @click="onDelete()"
                >
                    <t>Remove</t>
                </Button>
                <Button
                    :state="buttonState['save']"
                    cypress="planning-event-save-button"
                    @click="onValidate()"
                >
                    {{ translate(isNew ? 'Add' : 'Edit') }}
                </Button>
            </div>
        </form>

        <modalable
            ref="modalableSearchRolePanelOrganiser"
            class="modalable-1 small"
            :options="{ title: translate('Add Event Organiser') }"
        >
            <SearchRolePanel
                ref="searchRolePanelOrganiser"
                :has-roles="false"
            />
        </modalable>

        <modalable
            ref="modalableSearchUser"
            class="modalable-1 small"
            :options="{ title: translate('Add Event Participants') }"
        >
            <SearchRolePanel
                ref="searchUserPanel"
                :multiple-mode="true"
                :has-roles="false"
            />
        </modalable>
    </div>
</template>

<script>
import CompanyPlanningEventCollection from '@/models/CompanyPlanningEventCollection';
import PlanningTimeSlots from '@/components/resources/planningEvent/PlanningTimeSlots.vue';
import ParametersBox from '@/components/globals/ParametersBox.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import moment from 'moment';
import 'moment-timezone';
import HtUserProgramList from '@/components/globals/HtUserProgramList.vue';
import GuessOnWhichEmailItWillBeSend from '@/components/globals/GuessOnWhichEmailItWillBeSend.vue';
import EventTimezoneManager from '@/components/globals/EventTimezoneManager.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import RecurrenceUserConfig from '@/components/resources/recurrence/RecurrenceUserConfig.vue';
import api from '@/store/api';
import HtFormEditor from '@/components/globals/HtFormEditor.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';

export default {
    name: 'UserPlanningEventEdit',
    components: {
        HtFormEditor,
        PlanningTimeSlots,
        ParametersBox,
        HtFormSwitch,
        HtFormInput,
        HtUserProgramList,
        GuessOnWhichEmailItWillBeSend,
        EventTimezoneManager,
        HtKeyDatesInputOrder,
        RecurrenceUserConfig,
    },
    inject: ['modal'],

    props: {
        companyUserId: {
            type: [String, Number],
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
        from: {
            type: String,
            required: true,
        },
        intelligentSelection: {
            type: Array,
            required: false,
        },
        id: {
            type: [String, Number],
            required: false,
        },
        value: {
            type: Object,
            required: false,
        },
        collection: {
            type: CompanyPlanningEventCollection,
            required: false,
        },
    },

    data() {
        return {
            isNew: true,
            companyPlanningEvent: {
                id: null,
                task: {
                    company_user_program_key_date_id: null,
                    offset_availability_number: 0,
                    offset_availability_unit: 'immediately',
                    offset_end_number: 0,
                    offset_end_unit: 'days',
                    is_business_day: false,
                    datetime_availability: null,
                    datetime_end: null,
                },
                time_start: '',
                time_end: '',
                attendees: [],
                email_sending_offset: 0,
                timezone: moment.tz.guess(),
                company_user_id: this.companyUserId,
                company_user_program_id: this.companyUserProgramId,
                owner: null,
            },
            anchorDate: null,
            buttonState: {
                delete: 'idle',
                save: 'idle',
            },
            is_file_box_checked: false,
            professionalEmailSwitchDayOffset: null, // TODO voir ticket https://heyt.atlassian.net/browse/MAR-276
            textVariables: [
                {
                    name: 'enrollee_firstname',
                    label: 'Firstname',
                },
                {
                    name: 'enrollee_lastname',
                    label: 'Lastname',
                },
                {
                    name: 'enrollee_starting_date',
                    label: 'Starting date',
                },
                {
                    name: 'enrollee_job_title',
                    label: 'Job title',
                },
                {
                    name: 'enrollee_department',
                    label: 'Department',
                },
                {
                    name: 'enrollee_contract',
                    label: 'Contract',
                },
            ],
        };
    },
    computed: {
        sendToEmailTypeString() {
            if (
                this.userHasPersonnalEmail
                && this.offsetComputationIsBeforeDayOffsetSetting
            ) {
                return 'The onboardee will receive this invitation on their personal e-mail';
            }

            return 'The onboardee will receive this invitation on their professional e-mail';
        },
        now() {
            return this.$Utils.moment()
                .format('YYYY-MM-DD');
        },
        organiser() {
            return this.companyPlanningEvent.owner
                ? { company_user: this.companyPlanningEvent.owner }
                : null;
        },
        canDelete() {
            return !this.isNew && this.companyPlanningEvent.permissions && this.companyPlanningEvent.permissions.delete;
        },
        keyDateLabel() {
            return this.companyUserProgram.company_program.key_date.name_translated.toLowerCase();
        },

        // 8191 is the limit of gsuite event description
        descriptionValidation() {
            return 'max:8191';
        },
        recurrence() {
            return this.companyPlanningEvent.task?.recurrence || null;
        },
    },

    watch: {
        'item.is_delegated': function (value) {
            if (value && this.companyPlanningEvent.content_to_delegation === '') {
                this.companyPlanningEvent.content_to_delegation = this.companyPlanningEvent.content;
            }
        },
    },

    async created() {
        if (this.value) {
            this.isNew = false;
            this.companyPlanningEvent = this.value;

            if (this.value.companyFile) {
                this.is_file_box_checked = true;
            }
        }

        if (
            this.companyPlanningEvent
            && this.companyPlanningEvent.is_delegated
            && !this.companyPlanningEvent.content_to_delegation
        ) {
            this.companyPlanningEvent.content_to_delegation = this.companyPlanningEvent.content;
        }
    },

    methods: {
        openAddUsersModal() {
            this.$refs.searchUserPanel.init({
                onValidate: (attendees) => {
                    this.companyPlanningEvent.attendees = attendees;
                    this.$refs.modalableSearchUser.close();
                },
                selected: this.companyPlanningEvent.attendees,
                intelligentSelection: this.intelligentSelection,
            });
            this.$refs.modalableSearchUser.open();
        },

        resetOrganiser() {
            this.companyPlanningEvent.owner = null;
            this.companyPlanningEvent.owner_id = null;
        },

        openSearchRole() {
            this.$refs.searchRolePanelOrganiser.init({
                selected: this.companyPlanningEvent.owner ? [this.companyPlanningEvent.owner] : [],
                intelligentSelection: this.intelligentSelection,

                onSelect: (item) => {
                    this.$refs.modalableSearchRolePanelOrganiser.close();

                    this.companyPlanningEvent.owner_id = item.id;
                    this.companyPlanningEvent.owner = item;
                },

                onUnselect: () => {
                    this.$refs.modalableSearchRolePanelOrganiser.close();
                    this.resetOrganiser();
                },
            });
            this.$refs.modalableSearchRolePanelOrganiser.open();
        },

        close() {
            this.modal.close();
        },

        onValidate() {
            this.buttonState.save = 'loading';

            this.$validator.validateAll()
                .then((result) => {
                    if (!result) {
                        this.$Utils.firstParentScrollableToTop(this.$el);
                        this.buttonState.save = 'idle';
                        return;
                    }

                    if (this.isNew) {
                        if (this.from === 'foc' || this.from === 'bop') {
                            this.companyPlanningEvent.task.status = CompanyUserProgramTask.STATUS_DRAFT;
                        }

                        if (this.companyPlanningEvent.is_delegated) {
                            this.companyPlanningEvent.datetime_start = null;
                            this.companyPlanningEvent.datetime_end = null;
                            this.companyPlanningEvent.year_week = null;
                        }
                        api.user.planningEvents.create(this.companyPlanningEvent)
                            .then(() => {
                                this.buttonState.save = 'idle';
                                this.$emit('onUpdate');
                                this.modal.close();
                            });
                    } else {
                        if (this.from === 'foc' || this.from === 'bop') {
                            this.companyPlanningEvent.task.status = CompanyUserProgramTask.STATUS_DRAFT;
                        }

                        if (this.companyPlanningEvent.time_start && this.companyPlanningEvent.time_end) {
                            this.companyPlanningEvent.is_delegated = false;
                        }

                        api.user.planningEvents.update(
                            this.companyPlanningEvent.id,
                            this.companyPlanningEvent,
                        )
                            .then(() => {
                                this.buttonState.save = 'idle';
                                this.$emit('onUpdate');
                                this.modal.close();
                            });
                    }
                });
        },
        onDelete() {
            this.buttonState.delete = 'loading';
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this event? Once you click on delete, you will no longer be able to access this event.') })
                .then((resolve) => {
                    if (!resolve) {
                        this.buttonState.delete = 'idle';
                        return;
                    }

                    api.user.planningEvents.delete(this.companyPlanningEvent.id)
                        .then(() => {
                            this.buttonState.delete = 'idle';
                            this.$emit('onDelete');
                            this.modal.close();
                        });
                })
                .catch(() => {
                    this.buttonState.delete = 'idle';
                });
        },
        setRecurrence(value) {
            this.companyPlanningEvent.task.recurrence = value;
        },

    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.button-container {
    display: flex;
    justify-content: center;

    margin-top: 3.2rem;

    .button {
        padding: 1.5rem 4rem;
    }

    .button + .button {
        margin-left: 3rem;
    }
}

.event-offset-input {
    display: flex;
    align-items: center;

    .ht-form-input {
        width: 12.5rem;
        margin-right: 2rem;
    }
}
</style>
<style lang="scss">
.user-planning-event-edit {
    /* we reset some default styling messing everything */
    fieldset, .ht-form, .checkable-container, .flex-container {
        margin: 0 !important;
    }

    .parameters-box, .event-date-time {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
    }

    .wrapper-half-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.8rem;

        & > fieldset {
            width: auto;
        }
    }

    .input-order {
        gap: 0.8rem;

        .select {
            width: auto;
            flex-grow: 1;
        }
    }

    .event-offset-input {
        gap: 0.8rem;

        .ht-form-input {
            width: 12.5rem;
            margin-right: 0;
        }
    }

    .flex-container-full {
        margin-bottom: 0 !important;
    }

    .ht-form + .ht-form.ht-switch {
        margin-top: 0.8rem !important;
    }

    .event-date-time .checkable-container {
        margin-bottom: 1.5rem !important;

        .icon.cursor-help {
            height: 2.4rem;
            margin-left: 0.8rem;
        }
    }

    .flatpickr-calendar.inline {
        width: 100%;
    }
}
</style>
