import Model from '@tony.caron/node-model-proxy/Model';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import CompanyProgramResourceFilter from './CompanyProgramResourceFilter';
import CompanyProgramTaskCollection from './CompanyProgramTaskCollection';
import CompanyResource from './CompanyResource';

export default class CompanyProgramTask extends Model {
    static RESOURCEABLE_CLASS = 'App\\CompanyProgramTask';

    modelConfig() {
        return {
            collection: CompanyProgramTaskCollection,
        };
    }

    modelDefaultValues() {
        return {
            translations: {
                name: {},
                description: {},
            },
        };
    }

    modelCustomAttributes() {
        return {
            is_retroactive: false,
            send_retroactive_notif: false,
        };
    }

    modelRelations() {
        return {
            resource: () => this.belongsTo(CompanyResource, 'resourceable_id', 'id'),
            dependencies: () => this.belongsToMany(CompanyResourceProgram, 'company_resource_program_dependencies', 'company_resource_program_id', 'dependency_id'),
            companyProgramResourceFilter: () => this.hasMany(CompanyProgramResourceFilter, 'resource_id', 'id')
                .where(['component_name', '=', 'company_program_task']),
        };
    }
}
