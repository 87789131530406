<template>
    <div>
        <label v-if="label">
            <t>Upload file</t>
            <span class="sub-text">(<t :size="maxSize">Up to {size} MB</t>)</span></label>

        <UploadBar
            ref="original_nameInput"
            v-model="files[editingLang]"
            :media="media"
            :cypress="'document-field-file'"
            :max-size="maxSize"
            @onUploadProgress="$emit('update:button-state', 'loading')"
            @onUploadEnd="$emit('update:button-state', 'idle')"
            @input="onInput"
            @file-deleted="$emit('file-deleted')"
        />
        <input
            v-validate.disable="validationRules"
            type="hidden"
            :name="fileFieldName"
            :value="JSON.stringify(files)"
            :data-vv-as="translate('file')"
        >
        <div
            v-for="(errorMessage, index) in errors.collect('files')"
            :key="index"
            class="error-message"
        >
            <span><t>{{ errorMessage }}</t></span>
        </div>
    </div>
</template>

<script>
import UploadBar from '../globals/UploadBar/UploadBar.vue';

export default {
    name: 'TranslatableFiles',
    components: {
        UploadBar,
    },
    inject: ['$validator'],
    props: {
        label: {
            type: Boolean,
            default() {
                return true;
            },
        },
        fileFieldName: {
            type: String,
            default() {
                return 'files';
            },
        },
        editingLang: {
            type: String,
            required: true,
        },
        files: {
            type: Object,
            required: true,
        },
        validationRules: {
            type: Object,
            required: true,
        },
        media: {
            type: Array,
            default: () => ['office', 'pdf', 'image'],
        },
        maxSize: {
            type: Number,
            default: 20,
        },
    },
    methods: {
        onInput() {
            this.$emit('input', this.files);
            this.$emit('update:files', this.files);
        },
    },
};
</script>

<style scoped>

</style>
