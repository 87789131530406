<template>
    <div class="task-status-wrapper">
        <div
            v-if="withAnimation"
            ref="doneAnimation"
            class="done-animation"
        />
        <div
            class="task-status"
            :class="[status, {late: isLate}]"
        >
            <FontAwesomeIcon
                v-if="status == 'unavailable'"
                icon="lock"
                class="lock"
            />
            <span
                v-else-if="status == 'disabled'"
                class="disabled"
            />
            <FontAwesomeIcon
                v-else
                icon="check"
                class="check"
            />
        </div>
    </div>
</template>

<script>
import taskDoneAnimation from '@/assets/lottieAnimations/taskDone.json';
// Put in vue context when implementing next animation
import lottie from 'lottie-web';

export default {
    name: 'HtTaskStatus',
    props: {
        status: {
            type: String,
            required: false,
            default: 'pending',
            validator: (value) => ['pending', 'done', 'unavailable'].includes(value),
        },
        isLate: {
            type: Boolean,
            required: false,
            default: false,
        },
        withAnimation: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            animation: null,
        };
    },
    watch: {
        status() {
            if (this.animation && this.status === 'done') {
                this.animation.goToAndPlay(0);
            }
        },
    },
    mounted() {
        if (this.withAnimation) {
            this.animation = lottie.loadAnimation({
                container: this.$refs.doneAnimation,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: taskDoneAnimation,
            });
            this.animation.addEventListener('complete', () => {
                this.$emit('isTaskAnimationCompleted', true);
            });
        }
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
.task-status-wrapper {
    position: relative;
    .task-status {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: $neutral-300 solid 1px;
        background-color: $neutral-white;
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: relative;
        z-index: 2;

        &.late {
            border-color: $semantic-error;
        }
        .check {
            display: none;
            width: 10px;
            height: 10px;
            color: $neutral-300;
        }

        .disabled {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #FBFBFB;
        }

        .lock {
            display: none;
            width: 10px;
            height: 10px;
            color: $neutral-white;
        }

        &:hover {
            .check{
                display:block;
            }
        }
        &.done {
            background-color: $semantic-success-light;
            border: none;

            .check {
                display: block;
                color: $semantic-success;
            }
        }

        &.unavailable {
            cursor: not-allowed;
            background-color: $neutral-500;
            border: none;

            .lock {
                display: block;
            }
            .check {
                display: none;
            }
        }
    }
    .done-animation{
        position: absolute;
        top: 0px;
        left: 0;
        height: 37px;
        width: 37px;
        transform: translate(0, -45%);
        z-index: 1;
    }
}

</style>
