<template>
    <div
        class="headbar-menu-profile-dropdown"
        :class="profileDropdownClass + ' ' + getIsHoldingClass"
    >
        <div class="headbar-menu-profile-dropdown-item">
            <div
                v-if="companyList.length"
                class="headbar-menu-profile-dropdown-item-link"
            >
                <a
                    :data-cy="'headbar-switch-account'"
                    href="#"
                    @click="openChangeAccountModal"
                >
                    <FontAwesomeIcon :icon="['far', 'building']" />
                    <t>Change Account</t>
                </a>
            </div>
            <div
                v-if="!isHolding"
                class="headbar-menu-profile-dropdown-item-link"
            >
                <router-link
                    :data-cy="'headbar-my-account'"
                    :to="goToProfile"
                    @click.native="$emit('hide-menu')"
                >
                    <FontAwesomeIcon :icon="['far', 'eye']" />
                    <t>Account</t>
                </router-link>
            </div>
            <div
                v-if="!isHolding"
                class="headbar-menu-profile-dropdown-item-link"
            >
                <router-link
                    :data-cy="'headbar-settings'"
                    :to="goToAccount"
                    @click.native="$emit('hide-menu')"
                >
                    <FontAwesomeIcon :icon="['far', 'cog']" />
                    <t>Settings</t>
                </router-link>
            </div>
            <div
                v-if="isHolding || shared.session.companyUser.isAdmin()"
                class="headbar-menu-profile-dropdown-item-link"
            >
                <a
                    :data-cy="'headbar-online-guide'"
                    href="#"
                    @click="openGuide"
                >
                    <FontAwesomeIcon :icon="['far', 'link']" />
                    <t>Online guide</t>
                </a>
            </div>
            <div
                v-if="isSupportedLinkEnabled && shared.session.company.support_link"
                class="headbar-menu-profile-dropdown-item-link"
            >
                <a
                    :data-cy="'headbar-contact-us'"
                    href="#"
                    @click="openContactUs"
                >
                    <i class="fa fa-question-circle mr-3" />
                    <t>Contact us</t>
                </a>
            </div>
            <div class="headbar-menu-profile-dropdown-item-link">
                <a
                    :data-cy="'headbar-logout'"
                    href="#"
                    @click="onLogout"
                >
                    <FontAwesomeIcon :icon="['far', 'power-off']" />
                    <t>Logout</t>
                </a>
            </div>
        </div>
        <HtModal
            v-if="companyList.length > 0"
            ref="modalSwitchAccount"
        >
            <template #header>
                <div class="modal-header">
                    <t>Change account</t>
                    <div class="subtitle">
                        <FontAwesomeLayers class="icon">
                            <span style="color:#BDF2E2;">
                                <FontAwesomeIcon
                                    icon="circle"
                                    class="icon-circle"
                                />
                            </span>
                            <span style="color:#099468;">
                                <FontAwesomeIcon
                                    :icon="'check'"
                                    transform="shrink-10"
                                    class="icon-second"
                                />
                            </span>
                        </FontAwesomeLayers>
                        <t :company="shared.session.company.name">
                            Logged in on {company}
                        </t>
                    </div>
                </div>
            </template>
            <div class="switch-account-modal">
                <div>
                    <t>Change company</t>
                    <div
                        v-for="company in childCompanies"
                        :key="company.id"
                        class="switch-account-modal-button"
                        @click="switchToAccount(company.company_id)"
                    >
                        <span>{{ company.company_name }}</span>
                        <FontAwesomeIcon
                            :icon="'arrow-right'"
                            style="width: 9px; height: 9px;"
                        />
                    </div>
                </div>
                <div>
                    <t>Connect</t>
                    <div
                        class="switch-account-modal-button"
                        @click="switchToAccount(getHoldingCompany.company_id)"
                    >
                        <span>{{ getHoldingCompany.company_name }}</span>
                        <FontAwesomeIcon
                            :icon="'arrow-right'"
                            style="width: 9px; height: 9px;"
                        />
                    </div>
                </div>
            </div>
        </HtModal>
    </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import Utils from '@/Utils';
import HtModal from '../globals/HtModal.vue';

export default {
    name: 'HeadBarDropdown',
    components: {
        HtModal,
    },
    props: {
        showUserMenu: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapState('companies', [
            'companyList',
        ]),
        ...mapGetters('companies', [
            'getCompanyCustomize',
        ]),
        isSupportedLinkEnabled() {
            const companyCustomize = this.getCompanyCustomize(this.shared.session.company.id);

            return (!companyCustomize)
                ? !!this.shared.session.company.company_customize.models.find(
                    (companyCustomize) => 'support_link_is_enabled' in companyCustomize.content
                        && !!companyCustomize.content.support_link_is_enabled,
                )
                : !!companyCustomize && companyCustomize.supportLinkIsEnabled;
        },
        getIsHoldingClass() {
            return (this.shared.session.company.is_holding) ? 'holding' : '';
        },
        profileDropdownClass() {
            return this.showUserMenu ? '' : 'd-none';
        },
        goToAccount() {
            return {
                name: 'Account',
            };
        },
        goToProfile() {
            return {
                name: 'Profile',
                params: {
                    company_user_id: this.shared.session.companyUser.id,
                },
            };
        },
        goToLogin() {
            return {
                name: 'Login',
            };
        },
        childCompanies() {
            return this.companyList.filter((line) => (!line.company_is_holding));
        },
        getHoldingCompany() {
            return this.companyList.find((line) => line.company_is_holding);
        },
        isHolding() {
            return this.shared.session.company.is_holding;
        },
    },
    created() {
        this.$store.dispatch('companies/fetchCompanyList');
    },
    methods: {
        openGuide() {
            window.open('https://support.heyteam.com/knowledge', '_blank');
            this.$emit('hide-menu');
        },
        openContactUs() {
            window.open(this.shared.session.company.support_link, '_blank');
            this.$emit('hide-menu');
        },
        onLogout() {
            this.$http.post('auth/logout')
                .then(() => {
                    this.$Auth.logOut();

                    this.$router.push(this.goToLogin);
                });
        },
        openChangeAccountModal() {
            this.$refs.modalSwitchAccount.open();
            this.$emit('hide-menu');
        },
        switchToAccount(companyId) {
            Utils.autologin(companyId)
                .then((url) => {
                    url.searchParams.append('redirect', '/');
                    window.location = url.toString();
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~@/styles/bootstrap-custom";

@import "~bootstrap/scss/mixins";

.switch-account-modal {
    & > div {
        padding-top: 2.9rem;
        padding-left: 1.55rem;
        padding-right: 1.55rem;
        padding-bottom: 2.4rem;
        border-radius: 8px;
        background-color: #F5F7F8;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 2.2rem;
    }

    &-button {
        border: 1px solid #E5E6E6;
        background-color: white;
        margin: 0.4rem 0;
        height: 4.8rem;
        line-height: 1.7rem;
        padding: 1.55rem 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-weight: normal;
        font-size: 1.4rem;
    }
}

.headbar-menu-profile {
    &-dropdown {
        &:before {
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            top: -12px;
            right: 10px;
            border: solid transparent;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-bottom-color: #fff;
            border-width: 6px;
            margin-left: -6px;
        }

        z-index: 1000;
        position: absolute;
        width: 240px;
        top: 70px;
        right: 10px;
        border-radius: $border-radius-5;
        background: $color-white;
        border: 1px solid $grey-ultra-light;
        filter: drop-shadow(0 0 24px rgba(52, 46, 55, 0.1));

        &.holding {
            right: 0;
            top: 55px;
        }

        &-item {
            padding: 25px;

            &-link {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                a {
                    color: $color-text;
                    text-decoration: none;
                }

                & svg {
                    margin-right: 0.8rem;
                }
            }
        }

        &-footer {
            width: 100%;
            padding: 15px 25px;
            background: $bg-grey;
            border-bottom-left-radius: $border-radius-5;
            border-bottom-right-radius: $border-radius-5;

            a {
                color: $color-primary;
                text-decoration: none;
            }
        }
    }
}

.modal-header {
    display: flex;
    flex-direction: column;
    font-size: 2.8rem;

    .subtitle {
        font-size: 1.4rem;
        font-weight: normal;
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .icon svg {
            width: 1.8rem;
            height: 1.8rem;
        }
    }
}

@include media-breakpoint-down(xs) {
    .headbar-menu-profile {
        &-dropdown {
            top: 55px;
        }
    }
}

</style>
