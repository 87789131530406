<template>
    <HtFormMultiSelect
        :id="id"
        :name="id"
        :label="label"
        :is-single="true"
        :is-searchable="false"
        :allow-empty="false"
        :options="availableLanguages"
        :value="selectedLanguage"
        :disabled="disabled"
        class="language-select"
        @input="newLanguageSelected"
    >
        <template
            slot="singleLabel"
            slot-scope="{ option }"
        >
            <div class="option">
                <span class="rounded-flag">
                    <country-flag
                        :country="option.codeFlag"
                        size="big"
                    />
                </span>
                <span>{{ option.text }}</span>
            </div>
        </template>
        <template
            slot="option"
            slot-scope="{ option }"
        >
            <div class="option">
                <span class="rounded-flag">
                    <country-flag
                        :country="option.codeFlag"
                        size="big"
                    />
                </span>
                <span>{{ option.text }}</span>
            </div>
        </template>
    </HtFormMultiSelect>
</template>

<script>
import CompanyLanguage from '@/models/CompanyLanguage';
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import I18n from '@/modules/i18n/I18n';
import HtFormMixin from './HtFormMixin';

export default {
    name: 'HtLanguageSelect',
    components: {
        HtFormMultiSelect,
    },
    mixins: [
        HtFormMixin,
    ],
    props: {
        value: {
            type: CompanyLanguage,
            required: true,
        },
        label: {
            type: [String, Boolean],
            default() {
                return I18n.translate('Language');
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        customOptionLabel: {
            type: Function,
            default: (language) => I18n.translate('The content will be in {language}', { language: language.name.toLowerCase() }),
        },
    },
    data() {
        return {
            selectedLanguage: null,
        };
    },
    computed: {
        availableLanguages() {
            return this.shared.session.company.company_languages.enabled
                .reduce(
                    (languages, language) => (language.id === this.shared.session.company.company_language_id
                        ? [language].concat(languages)
                        : languages.concat(language)),
                    [],
                )
                .map((language) => ({
                    id: language.id,
                    value: language.id,
                    text: this.customOptionLabel(language),
                    codeFlag: language.codeFlag,
                }));
        },
    },
    watch: {
        value() {
            this.selectedLanguage = this.valueToSelectedLanguage(this.value);
        },
    },
    created() {
        this.selectedLanguage = this.valueToSelectedLanguage(this.value);
    },
    methods: {
        valueToSelectedLanguage(value) {
            return this.availableLanguages.find((language) => language.id === value.id);
        },
        newLanguageSelected(selectedLanguage) {
            this.$emit('input', this.shared.session.company.company_languages.enabled.find((language) => language.id === selectedLanguage.id));
            this.$emit('update:value', this.shared.session.company.company_languages.enabled.find((language) => language.id === selectedLanguage.id));
        },
    },
};
</script>

<style lang="scss">

.language-select {

    .multiselect__single {
        padding: 0;
    }

    .multiselect__tags {
        padding-left: 15px;
    }

    .multiselect__option {
        padding: 0.7rem;
        padding-left: 15px;
    }

    .multiselect--active {
        .multiselect__tags {
            padding-left: 15px;
        }

        .multiselect__single {
            padding: 0;
        }
    }

    .option {
        display: flex;
        align-items: center;
        gap: 1rem;

        .rounded-flag {
            transform: scale(0.75);
            -ms-transform: scale(0.75);
            -webkit-transform: scale(0.75);
            -moz-transform: scale(0.75);
        }
    }
}

</style>
