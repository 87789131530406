import Vue from 'vue';

export default {
    getAll(userId, params = {}) {
        return Vue.prototype.$http.get(`users/${userId}/requirement-categories`, {
            params,
        }).catch(() => {
            Vue.prototype.$Notifier('App').showError(
                Vue.prototype.$I18n.translate('An error occured !'),
            );
        });
    },

    available(userId, params = {}) {
        return Vue.prototype.$http.get(`users/${userId}/requirement-categories/available`, {
            params,
        }).catch(() => {
            Vue.prototype.$Notifier('App').showError(
                Vue.prototype.$I18n.translate('An error occured !'),
            );
            Promise.reject();
        });
    },

    get(userId, id, config = {}) {
        return Vue.prototype.$http.get(`users/${userId}/requirement-categories/${id}`, config).catch(() => {
            Vue.prototype.$Notifier('App').showError(
                Vue.prototype.$I18n.translate('An error occured !'),
            );
        });
    },

    create(userId, data = {}) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post(`users/${userId}/requirement-categories`, data).then(() => {
                Vue.prototype.$Notifier('App').showInfo(
                    Vue.prototype.$I18n.translate('Successfully stored'),
                );
                resolve();
            }).catch(({ response }) => {
                if (response.status === 400) {
                    Vue.prototype.$Notifier('App').showError(response.data.message);
                } else {
                    Vue.prototype.$Notifier('App').showError(
                        Vue.prototype.$I18n.translate('An error occured !'),
                    );
                }

                reject();
            });
        });
    },

    update(userId, id, data = {}) {
        return Vue.prototype.$http.put(`users/${userId}/requirement-categories/${id}`, data).then((res) => {
            Vue.prototype.$Notifier('App').showInfo(
                Vue.prototype.$I18n.translate('Successfully updated'),
            );

            return res.data;
        }).catch(() => {
            Vue.prototype.$Notifier('App').showError(
                Vue.prototype.$I18n.translate('An error occured !'),
            );
        });
    },

    delete(userId, id, data = {}) {
        return Vue.prototype.$http.delete(`users/${userId}/requirement-categories/${id}`, data).then(() => {
            Vue.prototype.$Notifier('App').showInfo(
                Vue.prototype.$I18n.translate('Successfully deleted'),
            );
        }).catch(() => {
            Vue.prototype.$Notifier('App').showError(
                Vue.prototype.$I18n.translate('An error occured !'),
            );
        });
    },

    setNotConcerned(userId, id, params = {}) {
        return Vue.prototype.$http.post(`users/${userId}/requirement-categories/${id}/set-not-concerned`, {
            params,
        }).catch(() => {
            Vue.prototype.$Notifier('App').showError(
                Vue.prototype.$I18n.translate('An error occured !'),
            );
        });
    },

    getSupervisorIdList(userId, id) {
        return Vue.prototype.$http.get(`users/${userId}/requirement-categories/${id}/supervisors-list`);
    },

    getArchived(userId, id, params = {}) {
        return Vue.prototype.$http.get(`users/${userId}/requirement-categories/${id}/archived`, params);
    },
};
